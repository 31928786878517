/** 
const { default: weaviate } = require('weaviate-ts-client');
console.log(process.env.REACT_APP_WEAVIATE_HOST, process.env.REACT_APP_WEAVIATE_API_KEY, process.env.REACT_APP_OPENAI_API_KEY)
const client = weaviate.client({
  scheme: 'https',
  host: "sandbox-engageai-g6igcngk.weaviate.network",  // Replace with your endpoint
  headers: {'X-OpenAI-Api-Key': process.env.REACT_APP_OPENAI_API_KEY},
 
});*/


export default async function DatabaseQueryService(query) {
    try{
        const options = {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify({ query:query }),
          };
          return fetch("https://tas-server.ddalabs.ai/query", options);
        }
    catch (e) {console.log(e)}
}
